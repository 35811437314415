<template>
  <div>
    <div
      class="event-detail ultomiris"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/main(mob).jpg'"
              media="(max-width: 767px)"
            />
            <source
              :srcset="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/main(tab).jpg'"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/main.jpg'"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/main.jpg'"
            />
          </picture>
          <div class="first-banner__text">
            <div class="first-banner__description">
              КОМПЛЕМЕНТ ФОРУМ <br />
              с международным участием:
            </div>
            <div class="first-banner__title">
              В фокусе атипичный гемолитико-уремический синдром у детей
            </div>
            <div
              class="button first-banner__button button_orange d-none d-xl-flex"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_orange d-none d-xl-flex"
              @click="openIframe()"
              v-else-if="!Object.keys(timerResult).length"
            >
              Смотреть
            </div>
            <div
              class="button first-banner__button button_orange d-md-none"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_orange d-md-none"
              @click="openIframe()"
              v-else-if="!Object.keys(timerResult).length"
            >
              Смотреть
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <!-- <div class="event-detail__timer mb-0 mt-md-8">
          <div class="event-detail__timer-title d-xl-none">
            Мероприятие начнется через:
          </div>
          <div
            v-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_white-blue px-6"
          >
            Смотреть
          </div>
          <div class="translation-counter">
            <div class="translation-counter__num">3 354</div>
            <div class="translation-counter__text">
              Врачей <br />
              уже с нами
            </div>
            <div class="translation-counter__img">
              <img
                :src="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/seemore/count-img.png'"
                alt=""
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                16 декабря, 9:30 – 16:30 (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Место</div>
              <div
                class="event-detail__date-item-value"
                v-html="EventDetail.place"
              ></div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                {{
                  EventDetail.types[0].name +
                  " " +
                  "\/" +
                  " " +
                  EventDetail.types[1].name
                }}
              </div>
            </div>
            <div
              class="button event-detail__date-button button_orange mt-0"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
          </div>
        </div>
        <div class="d-flex mb-8 d-md-none">
          <div
            class="button button_orange d-inline-flex mr-4"
            @click="scrollTo('experts')"
          >
            Эксперты
          </div>
          <div
            class="button button_orange d-inline-flex"
            @click="scrollTo('programm')"
          >
            Программа мероприятия
          </div>
        </div> -->
        <div class="second-banner">
          <div class="second-banner__text">
            <div class="second-banner__title">О КОМПЛЕМЕНТ ФОРУМЕ: </div>
            <div class="second-banner__description">
              Рады пригласить вас на встречу экспертов в рамках комплемент
              форума. На мероприятии обсудим проблемы диагностики аГУС, а также
              возможности терапии инновационным препаратом равулизумабом.
            </div>
            <div class="second-banner__button-container">
              <div
                class="button second-banner__button button_orange"
                @click="copyLink"
              >
                Поделиться с коллегой
              </div>
              <div
                class="button second-banner__button button_orange d-none d-xl-flex"
                @click="scrollTo('programm')"
              >
                Программа мероприятия
              </div>
            </div>
          </div>
          <picture>
            <source
              :srcset="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/sub(mob).jpg'"
              media="(max-width: 767px)"
            />
            <source
              :srcset="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/sub(tab).jpg'"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/sub.jpg'"
              media="(min-width: 1220px)"
            />
            <img
              class="second-banner__image"
              :src="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/ultomiris/sub.jpg'"
            />
          </picture>
        </div>
        <div class="lectors-block">
          <div class="lectors-title">Наши эксперты</div>
          <div class="lectors-greed">
            <div
              class="lectors-greed__item"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="lectors-greed__item-tag" v-html="lector.tag"></div>
              <div class="lectors-greed__item-name" v-html="lector.name"></div>
              <div
                class="lectors-greed__item-description"
                v-html="lector.description"
              ></div>
            </div>
          </div>
        </div>
        <DefaultSlider
          :color="'#DE823C'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <!-- <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div> -->
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>
        <div class="buttons mt-6">
          <div
            class="button first-banner__button button_orange d-none d-xl-flex"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_orange d-none d-xl-flex"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
          <div
            class="button first-banner__button button_orange d-md-none"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_orange d-md-none"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__main-info mt-8" id="programm">
          <!-- <div class="event-detail__about-title">
            Программа на 18 ноября 2023 г.
          </div>
          <div class="event-detail__tip">(указано московское время)</div> -->
          <InfoDrop
            v-for="(dropdown, ind) in program"
            :key="ind"
            @openInner="openInner(dropdown.title)"
            :item="{
              title: `<div class='programm-list__title'>${dropdown.title}</div><div class='programm-list__descr'>${dropdown.descr}</div>`,
              content: programmHTML(dropdown.items),
            }"
          />
        </div>
        <!-- <div class="buttons mt-6">
          <div
            class="button first-banner__button button_orange d-none d-xl-flex"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_orange d-none d-xl-flex"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
          <div
            class="button first-banner__button button_orange d-md-none"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_orange d-md-none"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
        </div> -->
        <InfoDrop
          :item="{
            title: 'Список сокращений',
            content: shortsList,
          }"
        />
      </div>
      <LibrarySlider
        v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
        :items="sliderItems"
        :color="'#DE823C'"
        :title="'Это может быть полезным'"
        @clickMaterials="clickMaterials"
      />
    </div>
    <div v-else-if="EventDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="EventDetail === false"><Error class="inPage" /></div>
    <Facecast
      :slug="$route.meta.fc_slug || 'ultomiris'"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
    <PopupEnd
      :eventName="'ultomiris'"
      :backUrl="{ name: 'Ultomiris' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import SimpleCarousel from "@/components/pageComponents/SimpleCarousel.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "EventDetail",
  components: {
    Choch,
    Error,
    SimpleCarousel,
    DefaultSlider,
    InfoDrop,
    LibrarySlider,
    Facecast,
    PopupEnd,
  },
  data: () => ({
    hideText: true,
    popup: false,
    openFrame: false,
    popupActivity: false,
    popupType: "",
    timerResult: { id: 0 },
    timerResultEnd: {},
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: false,
            variableWidth: false,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters(["user", "TextList", "VideoList", "PodcastsList"]),
    sliderItems() {
      let arr = [];
      if (this.PodcastsList && this.PodcastsList.length) {
        arr = arr.concat(
          this.PodcastsList.map((el) => ({ ...el, page: "PodcastDetail" }))
        );
      }
      if (this.TextList && this.TextList.length) {
        arr = arr.concat(
          this.TextList.map((el) => ({ ...el, page: "TextDetail" }))
        );
      }
      if (this.VideoList && this.VideoList.length) {
        arr = arr.concat(
          this.VideoList.map((el) => ({ ...el, page: "VideoDetail" }))
        );
      }
      return arr;
    },
    shortsList() {
      return `<div class="short-list">аГУС — атипичный гемолитико-уремический синдром <br><br>ТМА — тромботическая микроангиопатия</div>`;
    },
    lectors() {
      return [
        {
          tag: "Председатель",
          name: "Эмирова Хадижа Маратовна",
          description:
            "К.м.н, профессор кафедры педиатрии ФГБОУ ВО МГМСУ им. Евдокимова МЗ РФ, врач-нефролог Центра гравитационной хирургии крови и гемодиализа ГБУЗ ДГКБ св. Владимира, Москва",
        },
        {
          tag: "Председатель",
          name: "Цыгин Алексей Николаевич",
          description:
            "Д.м.н., профессор кафедры нефрологии ФГБОУ ВО МГМСУ им. А.И.Евдокимова МЗ РФ, президент «Творческого Объединения Детских Нефрологов», руководитель НИИ детской нефроурологии ФГАУ «НМИЦ Здоровья детей» МЗ России, заведующий нефрологическим отделением ФГАУ «НМИЦ Здоровья детей» МЗ России, Москва",
        },
        {
          tag: "Председатель",
          name: "Музуров Александр Львович",
          description:
            "К.м.н., доцент кафедры анестезиологии, реаниматологии и токсикологии детского возраста ФГБОУ ДПО РМАНПО МЗ РФ, заведующий Центром гравитационной хирургии крови и ЦГХК и ГД ДГКБ св. Владимира, Москва",
        },
        {
          tag: "Спикер",
          name: "Гема Арисета",
          description:
            "Профессор, отделение детской нефрологии университетского госпиталя Vall d'Hebron, Барселона, Испания",
        },
        {
          tag: "Спикер",
          name: "Абасеева Татьяна Юрьевна",
          description:
            "К.м.н., доцент кафедры педиатрии ФГБОУ ВО МГМСУ им. Евдокимова МЗ РФ, врач-нефролог ЦГХК и ГД ГБУЗ ДГКБ св. Владимира ДЗМ, Москва",
        },
        {
          tag: "Спикер",
          name: "Макарова Тамара Петровна",
          description:
            "Д.м.н., профессор кафедры госпитальной педиатрии ФГБОУ ВО КГМУ Минздрава России, Казань",
        },
        {
          tag: "Спикер",
          name: "Панкратенко Татьяна Евгеньевна",
          description:
            "К.м.н., доцент кафедры Трансплантологии, нефрологии и искусственных органов МОНИКИ им. М.Ф. Владимирского, врач-нефролог ЦГХК и ГД ГБУЗ ДГКБ св. Владимира ДЗМ, Москва",
        },
      ];
    },
    EventDetail() {
      return {
        id: 407,
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
          {
            id: 2,
            name: "Офлайн",
            code: "offline",
          },
        ],
        nosology: [
          {
            id: 6,
            title: "Нефрология",
            slug: "nephrology",
            icon: "/media/uploads/nosology/icons/2022/07/13/Ellipse_193.svg",
            active: true,
          },
          {
            id: 8,
            title: "Педиатрия",
            slug: "pediatrics",
            icon: null,
            active: false,
          },
        ],
        lector: [],
        name: "Комплемент форум\u2028с международным участием:  В фокусе атипичный гемолитико-уремический синдром у детей",
        description: "",
        banner_description: "",
        additional_info: "",
        date: this.$route.meta.date || "2023-12-16T09:30:00+03:00",
        date_end: "2023-12-16T16:30:00+03:00",
        place: "г. Санкт-Петербург, отель CORT INN",
        hash: "76ac884d9b0bd32a4afc54af028c86c35c7d081cb397eafb4021c9fa16611e79",
        webinar_id: "",
        room_id: "",
        external: false,
        external_link: "",
        is_active: true,
        banner_mobile: null,
        banner_tablet: null,
        banner_desktop: null,
        banner_desktop_l: null,
        banner_desktop_xl: null,
        banner_desktop_xxl: null,
      };
    },
    program() {
      return [
        {
          title: "Программа на 16 декабря 2023 г.",
          descr: "(указано московское время)",
          items: [
            {
              time: "09:30 – 09:35",
              title:
                "Приветственное слово Эмировой Х.М., Цыгина А.Н., Музурова А.Л.",
              description: "",
              icon: "talking.png",
            },
            {
              time: "09:35 – 09:45",
              title: "Интерактивное голосование",
              description: "Эмирова Х.М.",
              icon: "persona.png",
            },
            {
              time: "09:45 – 10:15",
              title:
                "Современная концепция патогенеза и клинические особенности атипичного гемолитико-уремического синдрома",
              description: "Абасеева Т.Ю.",
              icon: "persona.png",
            },
            {
              time: "10:15 – 10:35",
              title: "Подходы к ведению аГУС с учетом генетического варианта",
              icon: "persona.png",
              description: "Цыгин А.Н.",
            },
            {
              time: "10:35 – 10:45",
              title: "Видео «Бремя аГУС»",
              icon: "film.png",
            },
            {
              time: "10:45 – 11:15",
              title:
                "Результаты исследования равулизумаба при аГУС у детей, ранее получавших ингибиторы комплемента",
              description: "Музуров А.Л.",
              icon: "talking.png",
            },
            {
              time: "11:15 – 11:35",
              title: "Перерыв",
              description: "",
              icon: "coffee.png",
            },
            {
              time: "11:40 – 12:10",
              title:
                "Результаты исследования равулизумаба при аГУС у детей, ранее не получавших ингибиторы комплемента",
              description: "Гема Арисета",
              icon: "persona.png",
            },
            {
              time: "12:10 – 12:20",
              title: "Дискуссия",
              description: "",
              icon: "talking.png",
            },
            {
              time: "12:20 – 12:50",
              title:
                "Долгосрочные результаты антикомплементарной терапии аГУС у детей",
              description: "Эмирова Х.М.",
              icon: "persona.png",
            },
            {
              time: "12:50 – 13:20",
              title:
                "Атипичный гемолитико-уремический синдром, ассоциированный с антителами к фактору Н",
              description: "Гема Арисета",
              icon: "persona.png",
            },
            {
              time: "13:20 – 13:40",
              title: "Дискуссия",
              description: "",
              icon: "talking.png",
            },
            {
              time: "13:40 – 15:15",
              title: "Перерыв",
              description: "",
              icon: "coffee.png",
            },
            {
              time: "15:15 – 15:35",
              title: "Семейная форма аГУС",
              description: "Макарова Т.П.",
              icon: "persona.png",
            },
            {
              time: "15:15 – 15:35",
              title:
                "Ведение пациентов с аГУС после отмены ингибитора комплемента и терапевтические возможности рестарта терапии при рецидиве",
              description: "Генералова Г.А.",
              icon: "persona.png",
            },
            {
              time: "15:55 – 16:15",
              title: "Опыт ведения детей с ТМА на равулизумабе",
              description: "Панкратенко Т.Е.",
              icon: "persona.png",
            },
            {
              time: "16:15 – 16:30",
              title:
                "Когда мы рассматриваем лечение равулизумабом? Заключительная дискуссия",
              description: "Модераторы: Эмирова Х.М., Цыгин А.Н., Музуров А.Л.",
              icon: "persona.png",
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchAlerts",
    ]),
    openIframe() {
      const vm = this;
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          // if (typeof ym !== "undefined") {
          //   ym(91468266, "reachGoal", "bolshe", {
          //     bolshe: {
          //       "broadcast watch": {
          //         ...vm.$root.ymFields,
          //       },
          //     },
          //   });
          // }
          // this.openFrame = true;
          this.popup = true;
        } else {
          // this.openFrame = true;
          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "komplement-forum", {
            "komplement-forum": {
              "watch broadcast": {
                "В фокусе атипичный гемолитико-уремический синдром у детей": {
                  ...this.$root.ymFields,
                },
              },
            },
          });
        }
        this.openFrame = true;
      }
    },
    copyLink() {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "komplement-forum", {
          "komplement-forum": {
            share: {
              ...vm.$root.ymFields,
            },
          },
        });
      }
      var copyText = `${window.location.href}`;
      navigator.clipboard.writeText(copyText);
      this.fetchAlerts({
        alerts: "Ссылка скопирована в буфер обмена",
        type: "notify",
      });
    },
    scrollTo(selector) {
      if (selector === "programm") {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "komplement-forum", {
            "komplement-forum": {
              "programm click": {
                "В фокусе атипичный гемолитико-уремический синдром у детей": {
                  ...this.$root.ymFields,
                },
              },
            },
          });
        }
      }
      this.activeTab = parseInt(selector.replace(/^\D+/g, "")) - 1;
      const section = document.querySelector("#" + selector);

      // определяем отступ от верхнего края всей страницы (не области просмотра)
      const multyplier = this.$root.isMobile
        ? window.innerHeight
        : window.innerHeight / 2;
      const position = Math.round(section.offsetTop) + multyplier;

      // прокручиваем область просмотра до нужной позиции
      scrollTo({
        top: position,
        // плавно
        behavior: "smooth",
      });
    },
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="${require(`@/assets/img/ultomiris/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "komplement-forum", {
          "komplement-forum": {
            "material click": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    async toRegister(reg_button) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "komplement-forum", {
          "komplement-forum": {
            "reg click": {
              ...this.$root.ymFields,
            },
          },
        });
      }
      this.popup = true;
      return;

      // localStorage.setItem("source", `ultomiris`);
      // localStorage.setItem("backUrl", JSON.stringify({ name: "Ultomiris" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
    },
    openInner(title) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "komplement-forum", {
          "komplement-forum": {
            "programm click": {
              "В фокусе атипичный гемолитико-уремический синдром у детей": {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "forsiga", {
          forsiga: {
            "add to calendar": {
              ...this.$root.ymFields,
            },
          },
        });
      }
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    webinarEnd(e) {
      if (e.origin !== document.location.origin) {
        if (e.data && e.data === "ld_finish_call") {
          if (
            !localStorage.getItem("token") &&
            !!!Object.keys(this.user).length &&
            !!this.$route.query.access
          ) {
            // this.popup = true;
          }
        }
      }
    },
    closePopup() {
      this.popup = false;
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    const vm = this;

    await this.fetchTextMaterials({
      disease: ["hbp", "nephrology", "diabetes"],
    });
    await this.fetchVideoMaterials({
      disease: ["hbp", "nephrology", "diabetes"],
    });
    await this.fetchPodcasts({
      disease: ["hbp", "nephrology", "diabetes"],
    });
    if (
      !localStorage.getItem("token") &&
      !!!Object.keys(this.user).length &&
      !!this.$route.query.access &&
      !this.popup
    ) {
      setTimeout(() => {}, 10000);
    }
    window.addEventListener("message", this.webinarEnd, false);
    let video = document.querySelector(".event-detail__timer video");
    if (video) {
      video.addEventListener("play", function () {
        if (this.currentTime === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video start", {
              "video start": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        } else {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video resume", {
              "video resume": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        vm.cyclicVideo(Math.round(this.duration));
      });
      video.addEventListener("pause", function () {
        if (this.currentTime !== this.duration) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video pause", {
              "video pause": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        clearTimeout(vm.timer);
        vm.timer = null;
      });
      video.addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video end", {
            "video end": {
              "video name": vm.EventDetail.name,
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      });
      video.addEventListener("timeupdate", function () {
        let percent = Math.ceil((this.currentTime / this.duration) * 100);
        if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video percentage watched", {
              "video percentage watched": {
                "video percentage watched": percent,
                ...vm.$root.ymFields,
              },
            });
          }
          this.percent = percent;
        }
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {},
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        clearInterval(this.interval);
      }
    },
    async EventDetail() {
      if (this.EventDetail.external) {
        window.open(this.EventDetail.external_link, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1219px) {
    padding-top: 40px;
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    // background-color: #d0006f;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1219px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1219px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1219px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1219px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1219px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1219px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1219px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }
}

.features-grid {
  margin-top: 56px;
  @media screen and (max-width: 1219px) {
    display: none;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 33px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 181px;
    border: 2px solid;
    border-radius: 16px;
    padding: 18px 32px;
    &-title {
      margin-bottom: 8px;
      font-family: "TT Rationalist Trl", sans-serif;
      font-size: 48px;
      font-weight: 600;
      line-height: 59px;
      &.blue-item {
        color: #1b98d5;
      }
      &.red-item {
        color: #dd2726;
      }
      &.green-item {
        color: #c9da2b;
      }
    }
    &.blue-item {
      border-color: #1b98d5;
    }
    &.red-item {
      border-color: #dd2726;
    }
    &.green-item {
      border-color: #c9da2b;
    }
    &__descr {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column-reverse;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    border-radius: 0;
  }

  &__content {
    margin-bottom: 56px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    margin-bottom: 16px;
    @media screen and (max-width: 1219px) {
      & br {
        display: none;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    @media screen and (max-width: 1219px) {
      //   display: none;
    }
  }

  &__button {
    width: 200px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      max-width: none;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      background-color: #feede3;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.second-banner {
  position: relative;
  overflow: hidden;
  height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    border-radius: 16px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
    height: 430px;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
    }
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      padding: 24px;
      position: absolute;
      max-width: none;
      //   background-color: #dee2ee;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }
}
.third-banner {
  margin-top: 64px;
  position: relative;
  overflow: hidden;
  height: 485px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 1219px) {
    border-radius: 16px;
    margin-top: 32px;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
    }
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      padding: 24px 16px;
      background-color: #dee2ee;
      position: static;
      max-width: none;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1b98d5;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(4, 260px);
        overflow-x: auto;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(4, 280px);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-shrink: 0;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
        max-width: 177px;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  &__warning {
    padding: 16px;
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    border-radius: 16px;
    background-color: #1b98d5;

    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>

<style lang="scss">
.find_word {
  margin: 0 auto;

  width: 900px;
  height: 675px;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 675px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 825px;
  }
}

.digital_quiz {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.true_false {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.ultomiris + .modal-popup .modal-popup__content {
  max-width: fit-content;
}

.true_false iframe {
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1219px) {
    width: 100%;
    // height: 500px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    // height: 300px;
  }
}

.feedback iframe {
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1219px) {
    width: 100%;
    // height: 500px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    // height: 300px;
  }
}

.feedback {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  overflow-y: auto;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.short-list {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656969;
}
</style>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1219px) {
    padding-top: 40px;
  }

  &__tip {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1219px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1219px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 37px;
    border-radius: 16px;
    background: #de823c;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: stretch;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1219px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 24px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1219px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1219px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .translation-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: 1219px) {
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid #fff;
    }
    &__num {
      font-family: "Roboto Slab", sans-serif;
      font-size: 48px;
      font-weight: 400;
      line-height: 62px;

      @media screen and (max-width: 767px) {
        font-size: 40px;
      }
    }
    &__text {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    &__img {
      width: 90px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1219px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1219px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1219px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1219px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #de823c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px;
    margin-top: 32px;
    margin-bottom: 64px;
    background-color: #f8f8f8;
    border-top: 1px solid #ebefee;
    border-right: 1px solid #ebefee;
    border-bottom: 1px solid #ebefee;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  &__about-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
    }
  }

  &__date-button {
    margin-top: 14px;
    width: 192px;
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1219px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }
}

.ultomiris {
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}
</style>

<style lang="scss" scoped>
.lectors-block {
  margin-top: 64px;
  @media screen and (max-width: 1219px) {
    display: none;
  }
  .lectors-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: #de823c;
  }
  .lectors-greed {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 32px;
    &__item {
      border-radius: 16px;
      height: 100%;
      padding: 24px;
      background-color: #feede3;
      &-tag {
        margin-bottom: 16px;
        border-radius: 8px;
        padding: 4px 8px;
        background-color: #de823c;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
        width: fit-content;
      }
      &-name {
        margin-bottom: 8px;
        color: #3c4242;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &-description {
        color: #b2b4b4;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
}
.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;
  padding: 24px;
  background-color: #feede3;

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #de823c;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0;
  display: none;
  @media screen and (max-width: 1219px) {
    display: block;
  }
}
</style>

<style lang="scss">
.ultomiris .default-slider__title {
  color: #de823c;
}

.ultomiris .programm-list__title {
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 29px;
  color: #1f1f1f;
}
.ultomiris .programm-list__descr {
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b2b4b4;
}
</style>
